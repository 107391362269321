<template>
  <v-main style="overflow: auto">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card class="elevation-12">
            <v-window>
              <v-window-item>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-card-text class="mt-4">
                      <v-img src="@/assets/ukpl-logo-new.png" />
                      <h4 class="text-center mlt-4 mt-3">Reset Password</h4>
                      <v-form ref="resetpasswordform">
                        <v-text-field
                          :rules="[rules.required, rules.min]"
                          v-model="registeruser.password"
                          id="registerpassword"
                          label="Password"
                          name="registerpassword"
                          prepend-icon="lock"
                          type="password"
                          color="blue accent-3"
                        />
                        <v-text-field
                          v-model="confirmpassword"
                          :rules="[
                            rules.required,
                            rules.min,
                            passwordConfirmationRule,
                          ]"
                          id="confirmpassword"
                          label="Confirm Password"
                          name="confirmpassword"
                          prepend-icon="lock"
                          type="password"
                          color="blue accent-3"
                        />
                      </v-form>
                    </v-card-text>
                    <v-snackbar
                      :timeout="4000"
                      v-model="saveresult"
                      color="green accent-4"
                      rounded="pill"
                      elevation="24"
                    >
                      {{ message }}
                    </v-snackbar>
                  </v-col>
                  <v-col cols="12" md="4" class="blue accent-3">
                    <v-card-text class="white--text mt-12">
                      <h1 class="text-center display-1">RESET PASSWORD</h1>
                      <h5 class="text-center">
                        Click Below to Complete Reset Password
                      </h5>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn rounded outlined dark @click="resetpassword()"
                        >Reset Password</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import User from "../model/user";
import authservice from "../services/auth.service";

export default {
  name: "Home",
  data: () => ({
    resetpasswordemail: "",
    saveresult: false,
    registersuccess: false,
    registerfail: false,
    registerfailmessage: "",
    loginuser: new User("", "", "", "", ""),
    registeruser: new User("", "", "", "", ""),
    step: 1,
    password: "",
    registerpassword: "",
    confirmpassword: "",
    emailRules: [],
    mandatoryRules: [],
    rules: {
      required: (value) => !!value || "Field is Required.",
      min: (v) => v.length >= 8 || "Must be at least 8 characters",
      emailMatch: (v) => /.+@.+/.test(v) || "E-mail must be valid",
    },
    signinvalid: true,
    registervalid: true,
    message: "",
  }),
  props: {
    source: String,
  },
  computed: {
    passwordConfirmationRule() {
      return (
        this.registeruser.password === this.confirmpassword ||
        "Password must match"
      );
    },
  },
  watch: {
    saveresult: function () {
      if (this.saveresult === false) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    resetpassword() {
      if (!this.$refs.resetpasswordform.validate()) {
        console.log("reset password form failed validation");
        return false;
      }
      authservice
        .finalisePasswordReset(
          this.registeruser.password,
          this.$route.query.token
        )
        .then(
          (response) => {
            this.message = response.data.message;
            this.saveresult = true;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
  mounted: function () {
    this.nameRules = [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ];
    this.mandatoryRules = [(v) => !!v || "Field is required"];
    this.emailRules = [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ];
  },
};
</script>
